import React  from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';

import style from 'style';


const Container = styled.div`
  width: 350px;
  height: 770px;
  background: ${style.colors.primary};
  border-radius: 24px;
  padding: 10px 25px 25px 25px;
  color: ${style.colors.palette.white};
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  border: 1px solid ${style.colors.lightBorder};
  user-select: none;
  box-shadow: ${style.shadow(20)};

  ${({alternate}) => alternate && css`
    color: ${style.colors.primary};
    background: ${style.colors.palette.white};
    padding-top: 25px;
    height: 720px;
  `};
  
  @media (max-width: ${style.sizes.medium}) {
    width: 100%;
    max-width: 350px;
  }
  
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  .name {
    font-size: 20px;
    font-weight: 700;
  }
  
  .sub-title {
    font-size: 16px;
    ${({alternate}) => alternate && css`
      color: #6F6C90;
    `};
  }
`;

const LogoContainer = styled.div`
  height: 72px;
  width: 72px;
  background: ${style.colors.palette.white};
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${({alternate}) => alternate && css`
    background: #ECEBFF;
  `};
`;

const DescriptionContainer = styled.div`
  font-size: 13px;
  width: 100%;
  ${({alternate}) => alternate && css`
    color: #6F6C90;
  `};
`;

const PriceContainer = styled.div`
  width: 100%;
  .value {
    font-size: 50px;
    font-weight: 700;
    margin-right: 6px;
    line-height: 60px;
  }
  .pre-offer {
    color: ${style.colors.error};
    font-size: 30px;
    line-height: 30px;
  }
  .pre-offer-billed {
    color: ${style.colors.error};
  }
  .monthly {
    font-size: 18px;
    font-weight: 500;
    
    ${({alternate}) => alternate && css`
      color: #6F6C90;
    `};
  }
  .billing-cycle {
    font-size: 15px;
    font-weight: 400;
    margin-left: 2px;
    
    ${({alternate}) => alternate && css`
      color: #6F6C90;
    `};
  }
`;

const IncludedContainer = styled.div`
  width: 100%;
`;

const Button = styled.div`
  background: ${style.colors.palette.white};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  border-radius: 100px;
  color: ${style.colors.primary};
  font-size: 15px;
  font-weight: 700;
  max-width: 308px;
  margin-top: auto;
  
  :hover {
    cursor: pointer;
    background: ${style.colors.palette.dustyOrange};
    color: ${style.colors.palette.white};
  }
  
  ${({alternate}) => alternate && css`
    background: ${style.colors.secondary};
    color: ${style.colors.palette.white};
  `};
  
  ${({disabled}) => disabled && css`
    background: rgba(154, 154, 154, 0.16);
    color: #a1a1a1;
    :hover {
      cursor: not-allowed;
      background: rgba(154, 154, 154, 0.16);
      color: #a1a1a1;
    }
  `};
`;

const Tag = styled.div`
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.20);
  font-size: 13px;
  padding: 2px 20px;
`;

const Tier = (
  {
    name,
    icon,
    subTitle,
    description,
    price,
    billedPrice,
    billedType,
    benefits,
    alternate,
    onClick,
    buttonNode,
    disabled,
    discountedPrice,
    discountedBilledPrice,
  }) => {

  return (
    <Container alternate={alternate}>
      {!alternate && (
        <Tag>
          Most Popular
        </Tag>
      )}
      <Header alternate={alternate}>
        <LogoContainer alternate={alternate}>
          <img src={icon} height="36px" width="36px" alt="subscription-icon" />
        </LogoContainer>
        <div>
          <div className="sub-title">
            {subTitle}
          </div>
          <div className="name">
            {name}
          </div>
        </div>
      </Header>
      <DescriptionContainer alternate={alternate}>
        {description}
      </DescriptionContainer>
      <PriceContainer alternate={alternate}>
        <div>
          <span className="value">${discountedPrice ? discountedPrice : price}</span>
          {!!discountedPrice && <s className="value pre-offer">${price}</s>}
          <span className="monthly">/monthly</span>
        </div>
        <div className="billing-cycle">
          Billed ${discountedBilledPrice ? discountedBilledPrice : billedPrice}&nbsp;
          {!!discountedBilledPrice && <s className="pre-offer-billed">{billedPrice}</s>} + GST {billedType}
        </div>
      </PriceContainer>
      <div style={{fontSize: '16px', fontWeight: 700, width: '100%'}}>
        What’s included
      </div>
      <IncludedContainer>
        {benefits?.map((item, index) => <Benefit key={index} text={item} alternate={alternate} />)}
      </IncludedContainer>
      <Button alternate={alternate} disabled={disabled} onClick={params => !disabled && onClick(params)}>
        {buttonNode ? buttonNode : 'Get 30-Day Free Trial Now!'}
      </Button>
    </Container>
  );
};

Tier.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  billedPrice: PropTypes.number,
  billedType: PropTypes.string,
  benefits: PropTypes.arrayOf(PropTypes.string),
  alternate: PropTypes.bool,
  onClick: PropTypes.func,
  buttonNode: PropTypes.node,
  disabled: PropTypes.bool,
  discountedPrice: PropTypes.number,
  discountedBilledPrice: PropTypes.number,
};

const BenefitContainer = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 5px 0;
  
  .icon {
    font-size: 23px;
    margin-right: 15px;
  }
`;

const Benefit = ({ text, alternate }) => {
  return (
    <BenefitContainer>
      <FontAwesomeIcon
        color={alternate ? style.colors.secondary : style.colors.palette.white}
        className="icon"
        icon={faCircleCheck}
      />
      {text}
    </BenefitContainer>
  );
};

Benefit.propTypes = {
  text: PropTypes.string,
  alternate: PropTypes.bool,
};

export default Tier;

