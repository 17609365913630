import accPlusIcon from 'assets/acc-plus-icon.svg';
import enterpriseIcon from 'assets/enterprise-icon.svg';
import basicIcon from 'assets/basic-icon.svg';


export const TIER_STARTER = 'Starter';
export const TIER_ACCREDITATION_PLUS = 'Accreditation Plus';
export const TIER_ENTERPRISE = 'Enterprise';
export const TIER_NICHE_AND_REGIONAL = 'Niche & Regional';


// WARNING: Should exactly match with Braintree plan!!
export const PLAN_ID_ANNUAL_BASIC_ACCREDITATION = '20230914-ANNUAL-BASIC-ACCREDITATION';
export const PLAN_ID_QUARTERLY_BASIC_ACCREDITATION = '20230914-QUARTERLY-BASIC-ACCREDITATION';

export const PLAN_ID_ANNUAL_PLUS_ACCREDITATION = '20230914-ANNUAL-PLUS-ACCREDITATION';
export const PLAN_ID_QUARTERLY_PLUS_ACCREDITATION = '20230914-QUARTERLY-PLUS-ACCREDITATION';

export const PLAN_ID_ANNUAL_ENTERPRISE_ACCREDITATION = '20230914-ANNUAL-ENTERPRISE-ACCREDITATION';
export const PLAN_ID_QUARTERLY_ENTERPRISE_ACCREDITATION = '20230914-QUARTERLY-ENTERPRISE-ACCREDITATION';

export const PLAN_ID_QUARTERLY_NICHE_AND_REGIONAL_ACCREDITATION = '20240924-QUARTERLY-NICHE-AND-REGIONAL-ACCREDITATION';


export const TIER_ICONS = {
  'Starter': basicIcon,
  'Accreditation Plus': accPlusIcon,
  'Enterprise': enterpriseIcon,
  'Niche & Regional': basicIcon,
};


const TIERS = [
  {
    name: TIER_STARTER,
    subTitle: 'To get started',
    description: 'Get started with finding NDIS participants with population ' +
      'coverage targeting of approximately 1 to 5 million.',
    price: {
      annually: 59,
      quarterly: 83,
    },
    billedPrice: {
      annually: 699,
      quarterly: 249,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: TIER_ICONS[TIER_STARTER],
    alternate: true,
    benefits: [
      'Up to 3 service requests per month',
      'Advertise up to 3 services',
      'Accreditation badge',
      'Connect via direct connect button, social & web links only',
    ],
    btPlanId: {
      annually: PLAN_ID_ANNUAL_BASIC_ACCREDITATION,    // Should exactly match with Braintree plan
      quarterly: PLAN_ID_QUARTERLY_BASIC_ACCREDITATION,    // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'starter',    // Should exactly match with backend db
  },
  {
    name: TIER_ACCREDITATION_PLUS,
    subTitle: 'Increase exposure',
    description: 'Perfect for growing NDIS businesses with population coverage ' +
      'targeting of around 5 to 10 million & advanced endorsement options.',
    price: {
      annually: 109,
      quarterly: 149,
    },
    billedPrice: {
      annually: 1299,
      quarterly: 447,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: TIER_ICONS[TIER_ACCREDITATION_PLUS],
    alternate: false,
    benefits: [
      'Up to 5 service requests per month',
      'Advertise up to 5 services',
      'Larger prioritised directory listing with NDIS & Accreditation badging',
      'Connect via phone, direct connect button social & SEO friendly web link',
      'Larger geolocation targeting',
      'Showcase your photos with Photo Gallery',
    ],
    btPlanId: {
      annually: PLAN_ID_ANNUAL_PLUS_ACCREDITATION,   // Should exactly match with Braintree plan
      quarterly: PLAN_ID_QUARTERLY_PLUS_ACCREDITATION,   // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'accreditation_plus',   // Should exactly match with backend db
  },
  {
    name: TIER_ENTERPRISE,
    subTitle: 'For big companies',
    description: 'Nationwide coverage and unlimited access to all platform features.',
    price: {
      annually: 333,
      quarterly: 499,
    },
    billedPrice: {
      annually: 3996,
      quarterly: 1497,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: TIER_ICONS[TIER_ENTERPRISE],
    alternate: true,
    benefits: [
      'Unlimited service requests',
      'Advertise all your services',
      'Larger prioritised directory listing with NDIS & Accreditation badging ',
      'Connect via phone, direct connect button social & SEO friendly web link',
      'Nationwide geolocation targeting',
      'Showcase your photos with Photo Gallery',
    ],
    btPlanId: {
      annually: PLAN_ID_ANNUAL_ENTERPRISE_ACCREDITATION,   // Should exactly match with Braintree plan
      quarterly: PLAN_ID_QUARTERLY_ENTERPRISE_ACCREDITATION,   // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'enterprise',   // Should exactly match with backend db
  },
];

export const SPECIAL_TIER_NICHE_AND_REGIONAL = {
  name: TIER_NICHE_AND_REGIONAL,
  subTitle: 'Limited',
  description: 'For regional providers 150km+ outside of CBD locations servicing niche services.',
  price: {
    quarterly: 33,
  },
  billedPrice: {
    quarterly: 99,
  },
  billedType: {
    quarterly: 'Quarterly',
  },
  icon: TIER_ICONS[TIER_NICHE_AND_REGIONAL],
  btPlanId: {
    quarterly: PLAN_ID_QUARTERLY_NICHE_AND_REGIONAL_ACCREDITATION,    // Should exactly match with Braintree plan
  },
  subscriptionTierName: 'niche_and_regional',    // Should exactly match with backend db
};

export const PLAN_ID_TO_TIERS_MAP = {
  [PLAN_ID_ANNUAL_BASIC_ACCREDITATION]: TIERS[0],
  [PLAN_ID_QUARTERLY_BASIC_ACCREDITATION]: TIERS[0],

  [PLAN_ID_ANNUAL_PLUS_ACCREDITATION]: TIERS[1],
  [PLAN_ID_QUARTERLY_PLUS_ACCREDITATION]: TIERS[1],

  [PLAN_ID_ANNUAL_ENTERPRISE_ACCREDITATION]: TIERS[2],
  [PLAN_ID_QUARTERLY_ENTERPRISE_ACCREDITATION]: TIERS[2],

  [PLAN_ID_QUARTERLY_NICHE_AND_REGIONAL_ACCREDITATION]: SPECIAL_TIER_NICHE_AND_REGIONAL,
};

export default TIERS;
