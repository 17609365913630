import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button } from 'antd';

import * as track from 'lib/track';
import adAsset from 'assets/ad-youfoodz.png';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '1.5rem',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0.75rem',
    marginBottom: '2rem',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  left: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    maxWidth: '35rem',
    paddingRight: '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginBottom: '2rem',
    },
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    fontWeight: 700,
    color: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  button: {
    backgroundColor: '#99F2BF',
    color: theme.palette.common.darkSlateBlue,
    borderRadius: '0.75rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.25)',
    padding: '0.7rem 1rem',
    fontSize: '1rem',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  image: {
    height: '7rem',
  },
}));

const DirectoryAdCTA = () => {
  const classes = useStyles();

  const onClick = () => {
    const url = 'https://care.youfoodz.com/?utm_source=partnerships&utm_medium=directory&utm_campaign=clickability_directory';
    track.sendEvent('Directory Ad CTA banner - Youfoodz', 'click');
    window.open(url, '_blank');
  };

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.left}>
        <Typography variant="h2" className={classes.text}>
          Youfoodz now offers fresh, ready-to-eat meals for NDIS participants!
        </Typography>
        <Button type="primary" style={{marginTop: '15px', minWidth: '200px'}}>
          NDIS covers up to 70% of costs
        </Button>
      </div>
      <div className={classes.right}>
        <img src={adAsset} alt="youfoodz" className={classes.image} />
      </div>
    </div>
  );
};

export default DirectoryAdCTA;
